<template>
  <b-row>
    <b-col md="12">
      <b-card no-body>
        <b-card-header>
          <b-card-title>Günlük Kayıtları</b-card-title>
        </b-card-header>
        <b-table
          responsive="sm"
          :fields="fields"
          :items="dataList"
          striped
          fixed
        >
          <template #cell(ticimax_amount)="data">
            <div class="text-center">
              <b-badge variant="warning">
                {{ data.item.ticimax_amount | toCurrency }}
              </b-badge>
            </div>
          </template>
          <template #cell(local_amount)="data">
            <div class="text-center">
              <b-badge
                v-if="data.item.local_amount !== null"
                variant="success"
              >
                {{ data.item.local_amount | toCurrency }}
              </b-badge>
              <b-badge
                v-else
                variant="primary"
              >
                Kayıt Bulunamadı
              </b-badge>
            </div>
          </template>
          <template #cell(count)="data">
            <div class="text-center">
              {{ data.item.count }} Adet
            </div>
          </template>
          <template #cell(control)="data">
            <b-button
              :to="'/logs/view/' + data.item.id"
              variant="outline-primary"
              size="sm"
              block
            >
              DETAY
            </b-button>
          </template>
        </b-table>
        <b-card-footer><b-pagination
          v-model="currentPage"
          :total-rows="dataCount"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination></b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BRow, BCol, BTable, BCardFooter, BPagination, BButton, BBadge,
} from 'bootstrap-vue'

export default {
  name: 'UserTypes',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BButton,
    BBadge,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      search: '',
      fields: [
        {
          key: 'ticimax_id',
          label: 'ID',
          thStyle: { width: '100px' },
        },
        {
          key: 'stock_code',
          label: 'STOK KODU',
        },
        {
          key: 'ticimax_amount',
          label: 'ESKİ STOK',
          thStyle: { textAlign: 'center', width: '200px' },
        },
        {
          key: 'local_amount',
          label: 'YENİ STOK',
          thStyle: { textAlign: 'center', width: '200px' },
        },
      ],
      dataQuery: {
        id: this.$route.params.id,
        limit: 20,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['logs/getViewLogs']
    },
    dataCount() {
      return this.$store.getters['logs/getViewCount']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 20
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      this.$store.dispatch('logs/logsView', this.dataQuery)
    },
  },
}
</script>
<style scoped></style>
